import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from "../components/Hero";
import HomeCarousel from "../components/HomeCarousel";
import OurClients from "../components/OurClients";
import HolisticMarketing from "../components/HolisticMarketing";
import DataDrivenCampaigns from "../components/DataDrivenCampaigns";
import ClientRelationships from "../components/ClientRelationships";
import "./Home.css";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const aboutSectionRef = useRef(null); // Reference to the about section
  const aboutTitleRef = useRef(null); // Reference to the about title
  const aboutTextRef = useRef(null); // Reference to the about text

  useEffect(() => {
    // About Section Animation
    gsap.fromTo(
      aboutTitleRef.current,
      { opacity: 0, y: 50 }, // Initial state
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: aboutTitleRef.current,
          start: "top 80%", // Start animation when the top of the element hits 80% of the viewport
        },
      }
    );

    gsap.fromTo(
      aboutTextRef.current,
      { opacity: 0, y: 100 }, // Initial state
      {
        opacity: 1,
        y: 0,
        duration: 2,
        ease: "power4.out",
        scrollTrigger: {
          trigger: aboutTextRef.current,
          start: "top 80%", // Start animation when the top of the element hits 80% of the viewport
        },
      }
    );
  }, []);

  return (
    <div className="home">
      <Hero />
      <div className="about-section" ref={aboutSectionRef}>
        <h2 ref={aboutTitleRef}>About Overcrest</h2>
        <p ref={aboutTextRef}>
          At Overcrest Content Solutions, we are dedicated to crafting impactful
          marketing strategies that elevate brands to new heights. Our team of
          creative professionals brings a holistic approach to content
          development, combining innovative design, data-driven insights, and
          tailored campaigns to help businesses connect with their audience and
          achieve breakthrough results. We transform ideas into powerful
          narratives, ensuring every message resonates and leaves a lasting
          impression.
        </p>
      </div>
      <HomeCarousel />
      <OurClients />
      <div className="why-section">
        <h2 className="why-title">Why Choose Us?</h2>
        <div className="why-box">
          <HolisticMarketing />
          <DataDrivenCampaigns />
          <ClientRelationships />
        </div>
      </div>
    </div>
  );
};

export default Home;
