import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./HolisticMarketing.css";
import HolisticImg from "../images/holistic.png";

const HolisticMarketing = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { x: -100, opacity: 0 }, // Start off-screen to the left
      {
        x: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: contentRef.current,
      }
    );
  }, []);

  return (
    <div className="holistic-marketing" ref={contentRef}>
      <div className="holistic-text">
        <h2>Holistic and Creative Marketing Solutions</h2>
        <p>
          We offer a holistic approach to marketing, providing innovative and
          comprehensive strategies to elevate your brand across all channels.
        </p>
      </div>
      <img src={HolisticImg} alt="holistic img" />
    </div>
  );
};

export default HolisticMarketing;
