import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu"; // MUI menu icon
import Logo from "../images/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <AppBar
      position="sticky"
      className="appbar"
      style={{ backgroundColor: "rgba(28, 35, 39, 1)" }}
    >
      <Toolbar className="toolbar">
        {/* Logo */}
        <motion.div
          className="site-branding"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <Link to="/">
            <img src={Logo} alt="Site Logo" className="logo" />
          </Link>
        </motion.div>

        {/* Hamburger Icon for Mobile */}
        <IconButton
          edge="end"
          aria-label="menu"
          onClick={toggleMenu}
          sx={{ color: "white", display: { xs: "block", md: "none" } }} // Apply white color to the icon
          className="hamburger"
        >
          {isMenuOpen ? <FaTimes /> : <MenuIcon />}
        </IconButton>

        {/* Drawer for Mobile Menu */}
        <Drawer
          anchor="right"
          open={isMenuOpen}
          onClose={toggleMenu}
          className="drawer"
        >
          <List className="drawer-list">
            {["Home", "About", "Our Team", "What We Do", "Blog", "Contact"].map(
              (text, index) => (
                <ListItem button key={text} onClick={toggleMenu}>
                  <ListItemText>
                    <Link to={`/${text.toLowerCase()}`} className="drawer-link">
                      {text}
                    </Link>
                  </ListItemText>
                </ListItem>
              )
            )}
          </List>
        </Drawer>

        {/* Desktop Menu */}
        <nav className="desktop-menu">
          <motion.ul className="mainmenu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/team">Our Team</Link>
            </li>
            <li>
              <Link to="/projects">What We Do</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </motion.ul>
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
