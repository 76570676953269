import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./DataDrivenCampaigns.css";

import DigitalImg from "../images/datadriven.png";

const DataDrivenCampaigns = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { y: 50, opacity: 0 }, // Start from below
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: contentRef.current,
      }
    );
  }, []);

  return (
    <div className="data-driven-campaigns" ref={contentRef}>
      <div className="data-text">
        <h2>Data-Driven and Results-Oriented Campaigns</h2>
        <p>
          Our strategies are built on data-driven insights, ensuring that each
          campaign delivers measurable results for your business.
        </p>
      </div>
      <img src={DigitalImg} alt="digital img" />
    </div>
  );
};

export default DataDrivenCampaigns;
