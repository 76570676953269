import React from "react";

const OurTeam = () => (
  <div>
    <h1>Meet Our Team</h1>
    <p>Our experienced team brings creativity and expertise...</p>
  </div>
);

export default OurTeam;
