import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./ClientRelationships.css";
import ClientImg from "../images/client.png";

const ClientRelationships = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { scale: 0.8, opacity: 0 }, // Start smaller and invisible
      {
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: contentRef.current,
      }
    );
  }, []);

  return (
    <div className="client-relationships" ref={contentRef}>
      <div className="client-text">
        <h2>Personalized Client Relationships</h2>
        <p>
          We work closely with our clients, translating their vision into
          reality with personalized attention and care.
        </p>
      </div>
      <img src={ClientImg} alt="Client img" />
    </div>
  );
};

export default ClientRelationships;
