import React from "react";
import "./OurClients.css";

// Example logos (replace with your actual client logos)
import client1 from "../images/client1.png";
import client2 from "../images/client2.png";
import client3 from "../images/client3.png";
import client4 from "../images/client4.png";
import client5 from "../images/client5.png";
import client6 from "../images/client6.png";

const OurClients = () => {
  const clients = [client1, client2, client3, client4, client5, client6]; // Add more client logos as needed

  return (
    <div className="clients-section">
      <h2 className="clients-title">
        Our <span className="cycle-colors">Amazing</span> Clients
      </h2>
      <div className="clients-grid">
        {clients.map((client, index) => (
          <div key={index} className="client-logo-container">
            <img
              src={client}
              alt={`Client ${index + 1}`}
              className="client-logo"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClients;
