import React from "react";

const AboutUs = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>Overcrest is a top-tier digital marketing agency...</p>
    </div>
  );
};

export default AboutUs;
