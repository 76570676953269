import React, { useEffect, useRef, useState } from "react";
import anime from "animejs/lib/anime.es.js";
import { Box, Container, Typography } from "@mui/material";
import Mascot from "../images/mascot.png";
import "./Hero.css";

const Hero = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const mascotRef = useRef(null);

  const [currentSubtitle, setCurrentSubtitle] = useState(0); // Subtitle toggler
  const subtitles = [
    "Holistic Marketing for a Breakthrough Brand Experience.",
    "We connect your brand to the world, with love. Localisation is our core.",
  ]; // Two subtitles

  useEffect(() => {
    // Title Animation
    anime({
      targets: titleRef.current,
      opacity: [0, 1],
      translateY: [-50, 0],
      easing: "easeOutExpo",
      duration: 1000,
      delay: 500,
    });

    // Initial Subheading Animation
    anime({
      targets: subtitleRef.current,
      opacity: [0, 1],
      translateY: [50, 0],
      easing: "easeOutExpo",
      duration: 1000,
      delay: 1500,
    });

    // Mascot Animation
    anime({
      targets: mascotRef.current,
      translateY: [-20, 20],
      loop: true,
      direction: "alternate",
      easing: "easeInOutSine",
      duration: 2000,
    });

    // Looping Subtitle Animation every 7 seconds
    const subtitleInterval = setInterval(() => {
      anime({
        targets: subtitleRef.current,
        opacity: [1, 0],
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          setCurrentSubtitle((prevSubtitle) => (prevSubtitle === 0 ? 1 : 0)); // Toggle subtitle
          anime({
            targets: subtitleRef.current,
            opacity: [0, 1],
            duration: 1000,
            easing: "easeInOutQuad",
          });
        },
      });
    }, 7000);

    return () => clearInterval(subtitleInterval); // Clear interval on unmount
  }, []);

  return (
    <Box
      className="hero-section"
      sx={{ height: { xs: "150vh", md: "100vh" }, position: "relative" }}
    >
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Column for mobile
          justifyContent: "space-between",
          gap: "5px",
          alignItems: "center",
        }}
      >
        {/* Mascot Image */}
        <Box
          className="mascot-container"
          sx={{ order: { xs: 2, md: 2 }, mb: { xs: 1, md: 0 } }}
        >
          <img src={Mascot} alt="Mascot" className="mascot" ref={mascotRef} />
        </Box>

        {/* Hero Content */}
        <Box
          className="hero-content"
          sx={{
            textAlign: { xs: "center", md: "left" },
            order: { xs: 1, md: 1 },
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            className="hero-title"
            ref={titleRef}
            sx={{
              fontFamily: "var(--title-font)",
              textAlign: { xs: "center", md: "left" },
              fontWeight: "bold",
              color: "var(--aqua)",
              mb: 2,
            }}
          >
            Overcrest Content Solutions
          </Typography>
          <Typography
            variant="h5"
            component="p"
            className="hero-subtitle"
            ref={subtitleRef}
            sx={{
              color: "#fff",
              textAlign: { xs: "center", md: "left" },
              marginBottom: "0",
            }}
          >
            {subtitles[currentSubtitle]} {/* Dynamic subtitle */}
          </Typography>
        </Box>
      </Container>

      {/* Scroll Indicator */}
      <Box
        className="scroll-indicator"
        sx={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
        }}
      >
        <Typography variant="body1" sx={{ color: "#fff" }}>
          Scroll Down
        </Typography>
        <span className="scroll-down-arrow">&#8595;</span>
      </Box>
    </Box>
  );
};

export default Hero;
