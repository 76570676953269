import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router, // Importing BrowserRouter as Router
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import WhatWeDo from "./pages/WhatWeDo";
import OurTeam from "./pages/OurTeam";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Loader"; // Import the loader

// Define MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#fd8a58", // Orange from Overcrest logo
    },
    secondary: {
      main: "#6eecf4", // Turquoise from Overcrest logo
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "Dynamo W04 Bold Condensed"',
  },
});

// This component handles route changes and loading state
const MainContent = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Detect current route

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulate loading time

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />} {/* Conditionally render the Loader */}
      {!loading && (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/what-we-do" element={<WhatWeDo />} />
            <Route path="/team" element={<OurTeam />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainContent /> {/* MainContent is now inside the Router context */}
      </Router>
    </ThemeProvider>
  );
};

export default App;
